package hu.mkik.vb.portal.ui.proceeding.participation.modals

import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.model.ParticipationCandidate
import hu.mkik.vb.portal.ui.participationService
import hu.mkik.vb.portal.ui.proceeding.refreshParticipations
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.css.borderRadius4
import hu.simplexion.z2.browser.css.mb24
import hu.simplexion.z2.browser.css.p16
import hu.simplexion.z2.browser.css.w400
import hu.simplexion.z2.browser.html.div
import hu.simplexion.z2.browser.immaterial.schematic.field
import hu.simplexion.z2.browser.immaterial.schematic.touch
import hu.simplexion.z2.browser.material.modal.modal
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.schematic.Schematic
import hu.simplexion.z2.util.localLaunch

private class Invite : Schematic<Invite>() {
    var participation by uuid<Participation>()
    var fullName by string()
    var address by string()
    var email by email(blank = false)
}

suspend fun inviteParticipant(row: Participation, current : ParticipationCandidate) {
    modal(w400) {
        val invite = Invite().apply {
            participation = row.uuid
            fullName = row.fullName
            address = row.address
            email = row.email
        }

        title(strings.invite)

        localLaunch {

            body {
                field { current.fullName } readOnly true

                if (current.fullName != row.fullName) {
                    div(p16, borderRadius4, mb24) {
                        htmlElement.style.backgroundColor = "#ffef00"
                        htmlElement.style.color = "black"
                        text { strings.nameDifference }
                    }
                }

                field { current.address } readOnly true

                if (current.email != row.email) {
                    div(p16, borderRadius4, mb24) {
                        htmlElement.style.backgroundColor = "#ffef00"
                        htmlElement.style.color = "black"
                        text { strings.addressDifference }
                    }
                }

                field { current.email } readOnly true

                if (current.email != row.email) {
                    div(p16, borderRadius4) {
                        htmlElement.style.backgroundColor = "#ffef00"
                        htmlElement.style.color = "black"
                        text { strings.emailDifference }
                    }
                }
            }

            save(strings.invite) {
                invite.touch()
                if (!invite.isValid) return@save

                participationService.invite(invite.participation)

                snackbar(strings.successfulInvite)
                refreshParticipations()
                closeWith(Unit)
            }
        }
    }
}