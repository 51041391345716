package hu.mkik.vb.portal.ui.proceeding

import hu.mkik.vb.portal.model.*
import hu.mkik.vb.portal.ui.*
import hu.simplexion.z2.auth.model.Role
import hu.simplexion.z2.browser.immaterial.schematic.state
import hu.simplexion.z2.util.UUID

var feeTable = emptyList<ProceedingFeeCondition>()

var currentProceeding = ProceedingViewModel()

val currentParticipations
    get() = currentProceeding.participations.value

val currentCaseNumber
    get() = currentProceeding.proceeding.caseNumber

val userParticipation
    get() = userAccount.participation

val UUID<AccountVB>?.participation
    get() = currentParticipations.firstOrNull { it.participant == this }

val UUID<AccountVB>?.participantName
    get() = this.participation?.fullName

operator fun UUID<Participation>.get(from: List<Participation>) =
    from.firstOrNull { it.uuid == this }

val UUID<Participation>?.fullName
    get() = this?.let { it[currentParticipations]?.fullName }

val UUID<Participation>?.proceedingRole
    get() = this?.let { it[currentParticipations]?.proceedingRole }

val UUID<Role>?.name
    get() = currentProceeding.roles.singleOrNull { it.uuid == this }?.name ?: ""

val UUID<Role>.primary
    get() = primaryOrNull !!

val UUID<Role>.primaryOrNull
    get() = currentParticipations.firstOrNull { it.proceedingRole == this && it.primary && it.active }

val UUID<Role>.firstOrNull
    get() = currentParticipations.firstOrNull { it.proceedingRole == this && it.active }

val UUID<Role>.all
    get() = currentParticipations.filter { it.proceedingRole == this && it.active }

fun ProceedingSettings.arbitratorRoles() =
    listOf(
        chairmanRole,
        claimantArbitratorRole,
        respondentArbitratorRole
    )

suspend fun refreshTasks() {
    taskService.list(currentProceeding.proceeding.uuid).also {
        currentProceeding.tasks.value = it
    }
}

suspend fun refreshParticipations() {
    participationService.list(currentProceeding.proceeding.uuid).also {
        currentProceeding.participations.value = it
    }
}

suspend fun refreshEvents() {
    proceedingEventService.query(currentProceeding.proceeding.uuid, lastWeek()).also {
        currentProceeding.events.value = it
    }
}

suspend fun refreshDocuments() {
    documentService.queryDocuments(currentProceeding.proceeding.uuid).also {
        currentProceeding.documents = it.toMutableList()
    }
}

class ProceedingViewModel {

    val uuid
        get() = proceeding.uuid

    val caseNumber
        get() = proceeding.caseNumber

    var partial = true

    var proceeding = Proceeding()

    var documents = mutableListOf<Document>()

    var participations = state { emptyList<Participation>() }

    var roles = emptyList<ProceedingRole>()
    var settings = ProceedingSettings()
    var documentTypes = emptyList<DocumentType>()
    var folderTypes = emptyList<FolderType>()
    var uploadPermissions = emptyList<DocumentPermission>()

    var events = state { emptyList<ProceedingEvent>() }
    var tasks = state { emptyList<Task>() }
    var hearings = state { emptyList<Hearing>() }

    var directUploadFolders = emptyList<UUID<FolderType>>()

    var uploadTypes = mutableMapOf<FolderType, MutableList<DocumentType>>()

    var documentTypeMap = documentTypes.associateBy { it.uuid }
    var folderTypeMap = folderTypes.associateBy { it.uuid }

    var directUploadFolderMap = directUploadFolders.associateWith { folderTypeMap[it] !! }

    var uploadFolders = emptySet<UUID<FolderType>>()

    suspend fun load(uuid: UUID<Proceeding>) {
        val bundle = proceedingService.getBundle(uuid)
        partial = false

        settings = bundle.settings
        roles = bundle.roles
        documentTypes = bundle.documentTypes
        folderTypes = bundle.folderTypes
        uploadPermissions = bundle.uploadPermissions
        events.value = bundle.events
        tasks.value = bundle.tasks
        hearings.value = bundle.hearings

        proceeding = bundle.proceeding
        documents = bundle.documents.toMutableList()

        participations.value = bundle.participations

        directUploadFolders = uploadPermissions.map { it.folderType }

        documentTypeMap = documentTypes.associateBy { it.uuid }
        folderTypeMap = folderTypes.associateBy { it.uuid }
        directUploadFolderMap = directUploadFolders.associateWith { folderTypeMap[it] !! }

        for (permission in uploadPermissions) {
            val folderType = folderTypes.first { it.uuid == permission.folderType }
            val typeList = uploadTypes.getOrPut(folderType) { mutableListOf() }
            typeList += documentTypes.first { it.uuid == permission.documentType }
        }

        uploadFolders = buildUploadFolders()
    }

    fun buildUploadFolders(): Set<UUID<FolderType>> {
        val result = mutableSetOf<UUID<FolderType>>()

        fun addParent(folder: FolderType) {
            folder.masterFolder?.let {
                result += it
                addParent(folderTypeMap[it] !!)
            }
        }

        for (folder in directUploadFolderMap) {
            result += folder.value.uuid
            addParent(folder.value)
        }

        return result
    }

    suspend fun refreshTasks() {
        tasks.value = taskService.list(uuid)
    }

    suspend fun refreshHearings() {
        hearings.value = hearingService.list(uuid)
    }

    suspend fun refreshEvents() {
        events.value = proceedingEventService.query(uuid, lastWeek())
    }

}

//private fun List<Participation>.sort(roles: List<ProceedingRole>): List<Participation> {
//    val displayOrderMap = roles.associateBy({ it.uuid }, { it.displayOrder })
//    return this
//        .sortedWith(
//            compareBy(
//                { displayOrderMap[it.proceedingRole] ?: 100 },
//                { it.rank },
//                { it.fullName }
//            )
//        )
//}
