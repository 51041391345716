package hu.mkik.vb.portal.ui.proceeding.document.pages

import hu.mkik.vb.portal.model.Document
import hu.mkik.vb.portal.model.DocumentType
import hu.mkik.vb.portal.model.FolderType
import hu.mkik.vb.portal.model.Participation
import hu.mkik.vb.portal.ui.component.title
import hu.mkik.vb.portal.ui.document.documentModal
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.isSecretary
import hu.mkik.vb.portal.ui.proceeding.*
import hu.mkik.vb.portal.ui.proceeding.document.components.uploadReady
import hu.mkik.vb.portal.ui.proceeding.document.logic.DocumentFilter
import hu.mkik.vb.portal.ui.proceeding.document.modals.documentUpload
import hu.mkik.vb.portal.ui.strings
import hu.mkik.vb.portal.ui.util.content
import hu.mkik.vb.portal.ui.util.customChip
import hu.simplexion.z2.browser.browserStrings
import hu.simplexion.z2.browser.css.*
import hu.simplexion.z2.browser.html.*
import hu.simplexion.z2.browser.immaterial.schematic.attach
import hu.simplexion.z2.browser.immaterial.schematic.attachListener
import hu.simplexion.z2.browser.immaterial.select.singleChipSelect
import hu.simplexion.z2.browser.immaterial.table.Table
import hu.simplexion.z2.browser.immaterial.table.table
import hu.simplexion.z2.browser.material.button.filledButton
import hu.simplexion.z2.browser.material.button.iconButton
import hu.simplexion.z2.browser.material.button.textButton
import hu.simplexion.z2.browser.material.button.textLaunchButton
import hu.simplexion.z2.browser.material.em
import hu.simplexion.z2.browser.material.menu.dropdownMenu
import hu.simplexion.z2.browser.material.menu.menuItem
import hu.simplexion.z2.browser.material.px
import hu.simplexion.z2.browser.material.snackbar.snackbar
import hu.simplexion.z2.localization.locales.localized
import hu.simplexion.z2.localization.text.LocalizedText
import hu.simplexion.z2.util.UUID
import hu.simplexion.z2.util.formatByteLength
import hu.simplexion.z2.util.hereAndNow
import kotlinx.datetime.*
import kotlinx.dom.hasClass
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLInputElement

fun Z2.documents() {
    content {
        Documents(this, currentProceeding).main()
    }
}

class Documents(
    parent: Z2,
    val model: ProceedingViewModel
) : Z2(parent) {

    lateinit var tableView: Z2
    lateinit var gridView: Z2

    lateinit var tableViewIcon: Z2
    lateinit var gridViewIcon: Z2

    lateinit var filterContainer: Z2

    lateinit var table: Table<Document>

    val onGridView = false

    val documentFilter = DocumentFilter()

    val folderTypeMap = currentProceeding.folderTypeMap

    lateinit var activeFolders: List<FolderType>
    lateinit var activeTypes: List<DocumentType>
    lateinit var activeParticipants: List<Participation>

    val UUID<FolderType>.isActiveFolder
        get() = activeFolders.firstOrNull { this == it.uuid } != null

    var path = emptyList<FolderType>()

    val currentFolder
        get() = path.lastOrNull()

    override fun main(): Documents {
        addCss(mb24, heightFull, displayGrid)
        gridTemplateColumns = "1fr"
        gridTemplateRows = "min-content min-content 1fr"
        build()
        return this
    }

    fun build() {
        clear()

        activeFolders = currentProceeding.documents.map { it.folderType }.distinct().mapNotNull { folderTypeMap[it] }
        activeTypes = currentProceeding.documents.map { it.documentType }.distinct().mapNotNull { currentProceeding.documentTypeMap[it] }
        activeParticipants = currentProceeding.documents.map { it.submitter }.distinct().mapNotNull { it[currentParticipations] }

        title(currentProceeding.proceeding.caseNumber, strings.documents,
            leftActions = { uploadStatus() }
        ) { actions() }

        filters()

        gridView() css displayNone

        tableView()
    }

    fun Z2.chipInput(hint: LocalizedText) =
        input(
            displayFlex, alignItemsCenter, flexDirectionRow, borderOutline,
            h32, pl12, pr4, borderRadius8, labelLarge, onSurfaceVariantText,
            boxSizingBorderBox, outlineNone
        ) {
            with(htmlElement as HTMLInputElement) {
                placeholder = hint.toString()
                onFocus {
                    placeholder = ""
                }
                onBlur {
                    placeholder = hint.toString()
                }
            }

        }

    fun filters() =
        div(displayFlex, flexDirectionRow) {
            style.flexWrap = "wrap"
            filterContainer = this

            singleChipSelect(activeFolders, label = strings.filterByFolder) {
                documentFilter.folderFilter = it.valueOrNull?.uuid
            }.customChip()

            singleChipSelect(activeTypes, label = strings.filterByType) {
                documentFilter.documentTypeFilter = it.valueOrNull?.uuid
            }.customChip()

            singleChipSelect(activeParticipants, label = strings.filterBySender) {
                documentFilter.participantFilter = it.valueOrNull?.uuid
            }.customChip()

            val options = listOf(strings.today, strings.last7days, strings.last30days)
            singleChipSelect(options, label = strings.filterByDate) {
                when (it.valueOrNull) {
                    strings.today -> documentFilter.afterFilter = hereAndNow().date.atStartOfDayIn(TimeZone.currentSystemDefault())
                    strings.last7days -> documentFilter.afterFilter = hereAndNow().date.minus(7, DateTimeUnit.DAY).atStartOfDayIn(TimeZone.currentSystemDefault())
                    strings.last30days -> documentFilter.afterFilter = hereAndNow().date.minus(30, DateTimeUnit.DAY).atStartOfDayIn(TimeZone.currentSystemDefault())
                    else -> documentFilter.afterFilter = Instant.DISTANT_PAST
                }
            }.customChip()

            chipInput(strings.filterByName).apply {
                style.marginRight = "12px"
                onInput {
                    documentFilter.fileNameFilter = (htmlElement as HTMLInputElement).value.lowercase()
                }
            }
        }

    fun Z2.toggleHidden() {
        if (htmlElement.hasClass(displayNone.name)) {
            htmlElement.removeCss(displayNone)
        } else {
            htmlElement.addCss(displayNone)
        }
    }

    fun Z2.actions() {
        div(displayFlex, flexDirectionRow) {
            filledButton(strings.upload) {
                documentUpload()
            }

            iconButton(icons.filter, strings.filter) {
                filterContainer.toggleHidden()
            }

            tableViewIcon = iconButton(icons.tableView, strings.tableView) {
                tableViewIcon.addCss(displayNone)
                gridViewIcon.removeCss(displayNone)
                tableView.removeCss(displayNone)
                gridView.addCss(displayNone)
            } css displayNone

            gridViewIcon = iconButton(icons.gridView, strings.gridView) {
                tableViewIcon.removeCss(displayNone)
                gridViewIcon.addCss(displayNone)
                tableView.addCss(displayNone)
                gridView.removeCss(displayNone)
                if (documentFilter.folderFilter != null) {
                    snackbar(strings.folderFilterWithGridView)
                }
            }
        }
    }

    fun Z2.uploadStatus() {
        div(positionAbsolute, wFull, displayFlex, justifyContentCenter) {
            style.top = "-24px"
            div(displayFlex, flexDirectionRow, alignItemsCenter, bodyMedium, wMinContent, mb8).attach(uploadReady) {
                if (uploadReady.ready) {
                    div(displayFlex, flexDirectionRow, wMinContent, whiteSpaceNoWrap, justifyContentCenter, alignItemsCenter, pl16, pt8, pb8) {
                        style.borderLeft = "1px solid var(--md-sys-color-outline-variant)"
                        style.borderRight = "1px solid var(--md-sys-color-outline-variant)"
                        style.borderBottom = "1px solid var(--md-sys-color-outline-variant)"
                        style.backgroundColor = "var(--md-sys-color-secondary-container)"
                        style.borderBottomLeftRadius = "8px"
                        style.borderBottomRightRadius = "8px"
                        div {
                            +strings.documentUploadReady
                        }
                        textLaunchButton(strings.refresh) {
                            uploadReady.ready = false
                            refreshDocuments()
                            build()
                        }.also {
                            it.style.marginTop = "-12px"
                            it.style.marginBottom = "-12px"
                        }
                    }
                }
            }
        }
    }

    fun Z2.uploadTimeSelect() {
        dropdownMenu {
            menuItem("today", label = strings.today) { }
            menuItem("today", label = strings.last7days) { }
            menuItem("today", label = strings.last30days) { }
        }
    }

    fun Z2.gridView() =
        grid("1fr", "min-content 1fr") {
            gridView = this
            buildGridView()
        }

    fun Z2.buildGridView() {
        clear()

        folderPath()

        div(borderOutline, p16, borderRadius4, overflowYAuto) {
            foldersToShow().forEach {
                folderThumbnail(it)
            }

            div(displayContents).attach(documentFilter) {
                val documents = documentsToShow()

                documents.forEach {
                    documentThumbnail(it)
                }

                if (currentFolder?.uuid in model.directUploadFolderMap) {
                    if (documents.isEmpty()) div(pb16) { +strings.noDocuments }
                }
            }
        }
    }

    fun Z2.folderPath() {
        div(mb24, mt8) {
            div(cursorPointer, primaryText) { +strings.mainFolder }.onClick {
                path = emptyList()
                gridView.buildGridView()
            }
            var indent = 12
            path.forEach { folderType ->
                div(cursorPointer, primaryText) {
                    style.paddingLeft = indent.px
                    indent += 12

                    htmlElement.innerHTML += "↳&nbsp;&nbsp;"
                    +folderType.name

                    onClick {
                        while (path.lastOrNull() != null && path.lastOrNull()?.uuid != folderType.uuid) {
                            path = path.dropLast(1)
                        }
                        gridView.buildGridView()
                    }
                }
            }
        }
    }

    /**
     * List of the direct subfolders of [currentFolder] into which this participant
     * may upload.
     */
    fun foldersToShow(): List<FolderType> =
        model.folderTypeMap.values.filter { it.masterFolder == currentFolder?.uuid && (it.uuid.isActiveFolder || it.uuid in model.uploadFolders) }

    fun documentsToShow(): List<Document> =
        documentFilter.filter(model.documents.filter { it.folderType == currentFolder?.uuid })

    fun FolderType.isSubFolderOf(other: FolderType?): Boolean {
        if (this.masterFolder == null) return (other == null)
        if (other?.uuid == this.masterFolder) return true
        return model.folderTypeMap[this.masterFolder]!!.isSubFolderOf(other)
    }
//
//    fun uploadDialog(folderType: FolderType?) =
//        modal {
//            style.maxHeight = 90.vh
//            addClass(positionRelative, displayGrid)
//            gridTemplateColumns = "1fr"
//            gridTemplateRows = "min-content 1fr min-content"
//
//            title(strings.addDocument)
//
//            val bundle = fileBundle(model, if (folderType?.uuid in currentProceeding.directUploadFolders) folderType else null)
//
//            grid(p16, gridAutoFlowColumn, gridAutoColumnsMinContent, justifyContentFlexEnd) {
//                textButton(strings.cancel) { closeWith(Unit) }
//                textButton(strings.upload) {
//                    if (! bundle.validate()) return@textButton
//                    startUpload(bundle)
//                    closeWith(Unit)
//                }
//            }
//        }

    fun Z2.folderThumbnail(folderType: FolderType) {
        grid(gridGap16, cursorPointer) {
            gridTemplateColumns = "min-content 1fr"
            gridAutoRows = 48.px
            img(alignSelfCenter) {
                src = "/folder.png"
                style.height = (166 / 5).px
                style.width = (200 / 5).px
            }
            div(alignSelfCenter) { +folderType.name }
            onClick {
                path += folderType
                gridView.buildGridView()
            }
        }
    }

    fun Z2.documentThumbnail(document: Document) {
        grid(gridGap16, cursorPointer, pb16) {
            gridTemplateColumns = "min-content 300px min-content"
            gridAutoRows = 48.px
            img(alignSelfCenter) {
                src = "/document.png"
                style.height = 40.px
                style.width = 40.px
            }
            div(alignSelfCenter) {
                div(whiteSpaceNoWrap, overflowHidden) {
                    style.textOverflow = "ellipsis"
                    +document.name
                }
                div(bodySmall, displayFlex, flexDirectionRow) {
                    div { +document.uploadedAt.localized }
                    div(pl16) { +document.size.formatByteLength(1, browserStrings.zeroBytes, browserStrings.bytes) }
                }
            }
            a(primaryText, mb4) {
                (htmlElement as HTMLAnchorElement).also {
                    it.href = "/download/${document.uuid}"
                    it.download = document.name
                }
                style.textDecoration = "none"
                iconButton(icons.download, strings.download) { } css alignSelfCenter
            }
        }
    }

    class DocumentComparator : Comparator<Document> {
        override fun compare(a: Document, b: Document): Int {
            if (a.masterDocument == b.uuid) return 1
            if (a.uuid == b.masterDocument) return -1

            if (a.masterDocument != null) {
                if (b.masterDocument != null) {
                    return a.masterDocument!!.compareTo(b.masterDocument!!)
                } else {
                    return a.masterDocument!!.compareTo(b.uuid)
                }
            } else {
                // a.masterDocument == null
                if (b.masterDocument == null) {
                    return a.uuid.compareTo(b.uuid)
                } else {
                    return a.uuid.compareTo(b.masterDocument!!)
                }
            }
        }
    }

    fun Z2.tableView() {
        div(pt16, heightFull) {
            tableView = this

            table<Document> {
                this@Documents.table = this.table

                this.table.attachListener(documentFilter) {
                    // this.table.setData(documentFilter.filter(currentProceeding.documents).sortedWith(DocumentComparator()))
                    table.filter()
                    table.render()
                }

                multiLevel = true
                // table.traceMultiLevel = true
                getRowLevel = { if (it.masterDocument == null) 0 else 1 }
                filterFun = { row -> documentFilter.filterRow(row) }

                rowId = { it.uuid }
                query = { currentProceeding.documents.sortedWith(DocumentComparator()) }

                levelColumn { }

                column {
                    label = strings.referenceNumber
                    render = {
                        span(bodyMedium) {
                            +when {
                                it.privateType -> ""
                                it.masterDocument != null -> strings.attachment.localized
                                it.manualFiling -> strings.manualFiling.localized
                                it.dmsOneFilingNumber.isEmpty() -> strings.filingInProgress.localized
                                else -> it.dmsOneFilingNumber
                            }
                        }
                    }
                    comparator = { a, b -> a.dmsOneFilingNumber.compareTo(b.dmsOneFilingNumber) }
                    initialSize = 10.em
                }

                column {
                    label = strings.name
                    render = { span(bodyMedium) { +it.name } }
                    comparator = { a, b -> a.name.compareTo(b.name) }
                }

                column {
                    label = strings.size
                    render = { span(bodyMedium) { +it.size.formatByteLength(2, browserStrings.zeroBytes, browserStrings.bytes) } }
                    comparator = { a, b -> a.size.compareTo(b.size) }
                    initialSize = 6.em
                }

                column {
                    label = strings.submitter
                    render = { span(bodyMedium) { +(it.submitter[currentParticipations]?.fullName ?: "") } }
                    comparator = { a, b -> a.submitter[currentParticipations]?.fullName?.compareTo(b.submitter[currentParticipations]?.fullName ?: "") ?: 0 }
                    initialSize = 14.em
                }

                column {
                    label = strings.uploadTime
                    render = { span(bodyMedium) { +it.uploadedAt.localized } }
                    comparator = { a, b -> a.uploadedAt.compareTo(b.uploadedAt) }
                    initialSize = 10.em
                }

                column {
                    label = strings.folder
                    render = { span(bodyMedium) { +folderTypeMap[it.folderType]?.name } }
                    comparator = { a, b -> folderTypeMap[a.folderType]!!.name.compareTo(folderTypeMap[b.folderType]!!.name) }
                }

                column {
                    label = strings.actions
                    render = { row ->
                        style.marginLeft = "-12px"
                        a {
                            (htmlElement as HTMLAnchorElement).also {
                                it.href = "/download/${row.uuid}"
                                it.download = row.name
                            }
                            style.textDecoration = "none"
                            textButton(strings.download) { }
                        }
                        if (isSecretary) {
                            textButton(strings.details) {
                                documentModal(row) { table.redraw() }
                            }
                        }
                    }
                    initialSize = "min-content"
                }

            }
        }
    }

}