package hu.mkik.vb.portal.ui.proceeding

import hu.mkik.vb.portal.model.Proceeding
import hu.mkik.vb.portal.ui.icons
import hu.mkik.vb.portal.ui.proceeding.document.components.uploadReady
import hu.mkik.vb.portal.ui.proceeding.document.pages.documents
import hu.mkik.vb.portal.ui.proceeding.documentTemplate.pages.documentTemplates
import hu.mkik.vb.portal.ui.proceeding.edit.pages.editProceeding
import hu.mkik.vb.portal.ui.proceeding.finance.pages.incomingFinance
import hu.mkik.vb.portal.ui.proceeding.finance.pages.outgoingFinance
import hu.mkik.vb.portal.ui.proceeding.hearing.pages.hearings
import hu.mkik.vb.portal.ui.proceeding.history.pages.history
import hu.mkik.vb.portal.ui.proceeding.overview.overview
import hu.mkik.vb.portal.ui.proceeding.participation.pages.participationList
import hu.mkik.vb.portal.ui.proceeding.task.pages.tasks
import hu.mkik.vb.portal.ui.secretary
import hu.mkik.vb.portal.ui.strings
import hu.simplexion.z2.browser.html.Z2
import hu.simplexion.z2.browser.html.Z2Builder
import hu.simplexion.z2.browser.routing.NavRouter
import hu.simplexion.z2.util.localLaunch

object proceedingRouter : NavRouter(strings.proceeding, icons.proceeding) {

    val uuid by uuid<Proceeding>()

    val overview by render(strings.overview, icons.overview) { sub { overview() } }
    val edit by render(strings.edit, icons.edit, visibility = secretary) { sub { editProceeding() } }
    val documents by render(strings.documents, icons.documents) { sub { documents() } }
    val hearings by render(strings.hearings, icons.meetingRoom) { sub { hearings() } }
    val tasks by render(strings.tasks, icons.task) { sub { tasks() } }
    val participants by render(strings.participants, icons.participants) { sub { participationList() } }
    val incomingFinance by render(strings.incomingFinance, icons.fees, visibility = secretary) { sub { incomingFinance() } }
    val outgoingFinance by render(strings.outgoingFinance, icons.fees, visibility = secretary) { sub { outgoingFinance() } }
    val documentTemplate by render(strings.templates, icons.documents, visibility = secretary) { sub { documentTemplates() } }

    val history by render(strings.history, icons.history, visibility = secretary) { sub { history() } }

    override var default: Z2Builder = { sub { overview() } }

    fun Z2.sub(builder: Z2Builder) {
        localLaunch {
            if (currentProceeding.partial || currentProceeding.proceeding.uuid != uuid) {
                currentProceeding = ProceedingViewModel().also { it.load(uuid) }
                uploadReady.ready = false
            }
            builder()
        }

    }
}
